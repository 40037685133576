
export const App_Setting_Mode = "production"; // production | testing | localhost
const App_Version_Date = "15.09.2024 10AM"; // day.month.year Hour Am/PM
export const App_Version = (App_Setting_Mode === "production" ? "P" : "T") + App_Version_Date;
export const App_Name = "aaiotApp"; //office
export const App_ID = 5696788253; 
export const System_ID = 9516527453; // office System ID 

export var App_IsInTestingMode = false;

export const App_LocalStorage_Name = "appLocalStorageState";
export var App_LocalStorage_Is_Encrypted = false;
export var App_LocalStorage_Encrypted_KEY = "2024";


export var App_Server_Url = "https://www.cbmisdb.link/";
export var App_Server_Url_DownloadFiles = "https://tms-cbmis-production.s3.eu-central-1.amazonaws.com/";
export var App_Server_SendEmail_Path = "itcapp/sendmail";


if (App_Setting_Mode.toLowerCase() === "testing") {
  App_Server_Url = "https://www.cbmistesting.link/"; //"https://node.louayserver.click/" // "https://www.cbmistesting.link/"
  App_IsInTestingMode = true;
  App_LocalStorage_Is_Encrypted = false;
  App_Server_Url_DownloadFiles = "https://test-server-app.s3.eu-central-1.amazonaws.com/";
} else if (App_Setting_Mode.toLowerCase() === "localhost") {
  App_Server_Url = "http://localhost:4000/";
  App_IsInTestingMode = true;
  App_LocalStorage_Is_Encrypted = false;
  App_Server_Url_DownloadFiles = "https://test-server-app.s3.eu-central-1.amazonaws.com/";
}
// -------------------------------------------------------------------------------------------------------


export var App_Server_Url_GraphQL = App_Server_Url+"aaiotapp"  + "/graphql";
export var App_Server_Url_UploadFiles = App_Server_Url + "/upload/locally";
export var App_Server_Url_SendEmail = App_Server_Url + "/sendemail";

export const objUploadType = {
    cv : { fileType: "cv", id: "govID", sizeMb: 20, accept: "FILE" },

  };


export var App_Download_URL = "https://static-media-project.s3.eu-central-1.amazonaws.com/private-office-kuwait/"

export const Role_Public = 3553430577;

export const objMailType = {
  Contact_US: 5610433647,
  Enrollment: 6609632190,
};

export const objMailPurpose = {
  Contact_US: {
    Complaint: 2617277961,
    Suggestion: 1356313836,
    Inquiry: 2723785096,
  },
  Enrollment: {
    Enrollment_Request: 1641651315,
    Partnership_Request: 3329688095,
    Investment_Request: 3706261087,
  },
};