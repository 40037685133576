import * as appPages from "./appPages";

const objAppRouting = {
  Public_LandingPage: { url: appPages.Public_LandingPage, featureID: 8786507229  },
  Public_Committee: { url: appPages.Public_Committee, featureID: 0 },
  Public_Partnership: { url: appPages.Public_Partnership, featureID: 0 },
  Public_Investment: { url: appPages.Public_Investment, featureID: 0 },

  Contact_Us: { url: appPages.Contact_Us, featureID: 0 },
  FAQ: { url: appPages.FAQ, featureID: 0 },

};

export default objAppRouting;
