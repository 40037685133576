import React, { useLayoutEffect } from "react";
import { AppBar, Button, GroupBar, ClientDirection, Typography, Grid } from "@cbmisorg/client-app";
import * as appColor from "../../../../../../appHelper/appColor";
import useStickyHeader from "../../../../../../appHelper/appHook/useStickyHeader";
import { App_Download_URL, App_LocalStorage_Name } from "../../../../../../appHelper/appVariables";
import { clientFunction } from "../../../../../../appHelper/appFunctions";

import logo from "../../../../../../assets/logo.png";


function Header({ appState, appDispatch, navList, intRefreshCounter}) {
  const lang = appState?.clientInfo?.strLanguage;
  const blnIsScrolled = useStickyHeader(window?.screen?.height -60);

  const handelChangeLang = async (language) => {
    if (language !== lang) {
      appState.clientInfo.strLanguage = language;
      appState.clientInfo.strDataLanguage = language;
      appDispatch();
      ClientDirection.setDirectionByLanguage(language);
    }
  };

  const JXSButton = (
    <Button
      label={<Typography as="caption">{lang === "arb" ? "English" : "العربية"}</Typography>}
      size="sm"
      onClick={() => handelChangeLang(lang === "arb" ? "eng" : "arb")}
      mode="link"
      icon="translate"
      p-1
    />
  );

  useLayoutEffect(() => {
      const language = clientFunction?.getAppLocalStorage(App_LocalStorage_Name)?.clientInfo?.strLanguage
      ClientDirection.setDirectionByLanguage(language)
  }, [])

  return (
    <React.Fragment>
      <AppBar
        justify={"start"}
        className={!blnIsScrolled?"cbmis-app-bar-fixed":"cbmis-app-bar-Limiter"}
        sideBarTitle={JXSButton}
        sideBarClose={false}
        sideBarPosition={lang==="arb"?"right":"left"}
        key={String(intRefreshCounter)}
        >

        <GroupBar sxMode={"navbar"} ssMode={"navbar"}>
          {blnIsScrolled ? <img src={logo} alt="" height={"50px"} style={{ maxWidth: "98%" }} /> : ""}
          {}
        </GroupBar>
        <GroupBar sxMode="navbar" ssMode="sidebar">
          {navList[0]}
        </GroupBar>
        <GroupBar sxMode="navbar" ssMode="none" position="end">
          {JXSButton}
        </GroupBar>



      </AppBar>
    </React.Fragment>
  );
}

export default Header;
