import { createContext } from "react";
import { AppProvider } from "@cbmisorg/client-app";

import * as appVariables from "../../appHelper/appVariables";

export const AppContext = createContext({ appState: {}, appDispatch: () => undefined });

const appInitialState = {
  clientInfo: {
    dtmLastRequest: "",
    blnIsUserLoggedIn: false,
    blnIsAppStateLive: false,
    strLanguage: "eng",
    strDataLanguage: "eng",
    blnClientOnline: true,
    strAppName: appVariables.App_Name,
  },
  userInfo: {
    bigUserID: 0,
    bigSystemID: 0,
    strFullName: "",
    strUserEmail: "",
    strUserPhone: "",
    strUsername: "",
    bigUserRoleID: appVariables.Role_Public,
    bigCountryID: 0,
    lstUserFeature: [],
    txtLoginKeys: [],
    txtLoginIP: [],
    strLastLoginUserAgent: "",
    dtmLastLogin: "",
  },
};

export default AppProvider(
  appInitialState,
  AppContext,
  appVariables.App_LocalStorage_Name,
  appVariables.App_LocalStorage_Is_Encrypted,
  appVariables.App_LocalStorage_Encrypted_KEY,
  appVariables.App_IsInTestingMode
);
