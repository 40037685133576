import React, { useContext,  useState } from 'react'
import { BtnGoToTop, Button, Nav, Typography, Loader } from '@cbmisorg/client-app'
import { dictionary } from '../../../../../appHelper/appLanguage/dictionary'
import { AppContext } from '../../../../../configuration/contextapi/AppContext'

import Header from './sections/Header'
import Hero from './sections/Hero'
import ParallaxScrollingApp from '../../../../sharedUi/ParallaxScrollingApp'
import Footer from './sections/Footer'
import AlSabahFamily from './sections/AlSabahFamily'
import EmirKuwait from './sections/EmirKuwait'
import VisionOfKuwait from './sections/VisionOfKuwait'
import MessageOfSheikhMuhammad from './sections/MessageOfSheikhMuhammad'
import OurGoal from './sections/OurGoal'
// import Initiatives from './sections/Initiatives'
import JoinUs from './sections/JoinUs'
import ContactUs from './sections/ContactUs'
import OurExperiences from './sections/OurExperiences'
import OurServices from './sections/OurServices'



function Home() {
  const {appState,appDispatch} = useContext(AppContext)
  const lang = appState?.clientInfo?.strLanguage
  const labels = dictionary?.components?.public?.header

  
  const [stateInfo, setStateInfo] = useState({intRefreshCounter: 0})

  const closeDrawer = () => {
    ++stateInfo.intRefreshCounter;
    setStateInfo({ ...stateInfo });
  };

  return (
    <>
     <Header
      appState={appState} appDispatch={appDispatch}
      navList={[
      <React.Fragment>
        <Nav label={<Typography>{labels.home?.[lang]}</Typography>}
            menu={[
              <Nav label={<Typography>{dictionary?.components?.public?.landingPage?.home?.AlsabahFamily?.title?.[lang]}</Typography>} path="#alSabahFamily" onClick={closeDrawer} />,
              <Nav label={<Typography>{labels?.emirKuwait?.[lang]}</Typography>} path="#emirKuwait" onClick={closeDrawer} />,
              <Nav label={<Typography>{labels?.visionofKuwait?.[lang]}</Typography>} path="#visionofKuwait" onClick={closeDrawer} />,
              <Nav label={<Typography>{labels?.messageofSheikhMuhammad?.[lang]}</Typography>} path="#messageofSheikhMuhammad" onClick={closeDrawer} />,
            ]}
        />
        <Nav label={<Typography>{labels?.ourGoal?.[lang]}</Typography>} path="#ourGoal" onClick={closeDrawer} />
        <Nav label={<Typography>{labels?.ourExperiences?.[lang]}</Typography>} path="#ourExperiences" onClick={closeDrawer} />
        <Nav label={<Typography>{labels.ourServices?.[lang]}</Typography>} path="#ourServices" onClick={closeDrawer} />
        {/* <Nav label={<Typography>{labels.initiatives?.[lang]}</Typography>} path="#initiatives" onClick={closeDrawer} /> */}
        <Nav label={<Typography>{labels.joins?.[lang]}</Typography>} path="#joinUs" />
        <Nav label={<Typography>{labels?.contactUs?.[lang]}</Typography>} path="#contactus" onClick={closeDrawer} />
        </React.Fragment>,
         <React.Fragment>
            <Button
              label={<Typography as="body2" color={'secondary'}>
                            {labels?.groupe?.[lang]}
                    </Typography>}
                onClick={()=>window?.open("https://www.al-sabahgroup.com/","_blank")}
            />
         </React.Fragment>
        ]}
        intRefreshCounter={stateInfo?.intRefreshCounter}

      />
      <Hero blnVideo={true}/>
      <React.Suspense fallback={<Loader color="primary" backdrop={true}  sx={{position:"absolute !important", bottom:"10px !important"}}/>}>
        <AlSabahFamily lang={lang} />
        <EmirKuwait lang={lang} />
        <VisionOfKuwait lang={lang} />
        <MessageOfSheikhMuhammad lang={lang} />
        <ParallaxScrollingApp />

        <OurGoal lang={lang} />
        <ParallaxScrollingApp />

        <OurExperiences lang={lang} />
        <ParallaxScrollingApp pt-0 mt-0/>

        <OurServices lang={lang} />
        <ParallaxScrollingApp />

        {/* <Initiatives lang={lang} />
        <ParallaxScrollingApp /> */}

        <JoinUs  lang={lang} />

        <ParallaxScrollingApp />
        <ContactUs lang={lang} />
   
        <Footer lang={lang} />
        <BtnGoToTop className={lang==="arb"?"arbLang":""}/>
      </React.Suspense>


    </>
  )
}

export default Home



