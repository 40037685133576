import React from "react";
import { ParallaxScrolling } from "@cbmisorg/client-app";
import { App_Download_URL } from "../../appHelper/appVariables";
 
const classes = {
  parallaxScrolling: {
    minHeight: "45px !important",
    maxHeight: "45px !important",
    position: "relative",
  },
};

function ParallaxScrollingApp() {
  return (
    <React.Fragment>
      <ParallaxScrolling src={App_Download_URL + "kuwait_city_hero.jpg"} sx={classes?.parallaxScrolling} loading="lazy"  /> 
    </React.Fragment>
  );
}

export default ParallaxScrollingApp;
