import React  from 'react'
import { Container, Grid } from '@cbmisorg/client-app';
import * as appColor from '../../../../../../appHelper/appColor'

import { App_Download_URL } from "../../../../../../appHelper/appVariables";
import logo from "../../../../../../assets/logo.png";

const classes = {
  background: {
      background: `url(${App_Download_URL + "kuwait_city_hero.jpg"}) !important`,
      backgroundRepeat: "no-repeat !important",
      backgroundPosition: "center !important",
      backgroundSize: "cover !important",
      width:"100% !important",
      height:"99vh !important",
      xs:{
        height:"50vh !important", 
        // minHeight:"45vh"
      }
  },
  backgroundBlack:{
    "&before":{
      content:"",
      position:"absolute",
      width:"100%",
      height:"100%",
      display:"block",
      background:appColor?.App_Dark_Color,
      opacity:"0.5",
      borderRadius:"12px"
    },
  },
};

function Hero({blnVideo}) {

  return (
    <React.Fragment>
     <Container sx={{...classes?.background}} m-0>
      <video autoPlay muted playsInline controls={false} preload='none' className='heroVideo'  poster={App_Download_URL + "kuwait_city_hero.jpg"} >
        {
          blnVideo && (<source src={App_Download_URL + "hero.mp4"} type='video/mp4' />)
        }
      </video>
      <Grid container justify="center" sx={{position:"absolute", top:"25%"}}>
        <Grid item xs="12" md="6" lg="4"  alignSelf="center">
          <Container sx={classes?.backgroundBlack} py-2>
            <Grid container justify="center" spacing={2}>
              <Grid item xs="12">
                <img src={logo} alt="" height={"190px"} style={{maxWidth:"98%"}} />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
     </Container>

    </React.Fragment>
  )
}

export default Hero