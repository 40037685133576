import { Avatar, Box, Button, Container, Grid, Icon,  Typography } from '@cbmisorg/client-app';
import React, { useEffect } from 'react'
import * as appColor from '../../../../../../appHelper/appColor'
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary';
import { appStyle } from '../../../../../../appHelper/appStyle';

const lstIcon=["QueryStats","AccountBalance","work","PeopleAlt"]

const classes = {
    background: {
        backgroundImage:`url(${require("../../../../../../assets/images/intbg1.jpg")}) !important`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width:"100%  !important",
        height:"100%",
    },
    box: {
      height:"100% !important",
      minHeight:"190px !important",
      background:`${appColor?.App_Light_Color} !important`,
      md:{
        minHeight:"100% !important",
      }
    },
    title: {
      fontStyle: "italic",
      textAlign: "start",
      wordSpacing:"4px",
      position:"absolute",
      top:"-18px", left:"50%",
      background:appColor?.App_Light_Color,
      color:appColor.App_Primary_Color,
      transform:"translateX(-50%)",
      whiteSpace: "nowrap",
    },
  };

function JoinUs({lang}) {
    const labels = dictionary?.components?.public?.landingPage?.home?.joinUs

    useEffect(() => {
    }, [])

    return (
        <React.Fragment>
          <Grid container id="joinUs" py-10 my-5  px-0 spacing={0}>
            <Grid item xs="12"   container  justify="center">
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs="12" sx={appStyle?.titleContainer} mb-2>
                        <Typography as="h5"sx={appStyle?.title2} px-3>{labels?.title?.[lang]}</Typography>
                    </Grid>
                    <Grid item xs="12">
                        <Typography as="body1" px-3>{labels?.p1?.[lang]}</Typography>
                    </Grid>
                    <Grid item xs="12" container spacing={2} justify="center" alignSelf={'flex-start'}>
                    {
                      labels?.lst?.map((item,index)=>{
                        return(
                          <Grid item xs="12" lg="6"  key={index}>
                            <Box outline="2" sx={classes?.box}>
                                <Grid container justify={'center'}>
                                  <Grid item>
                                      <Avatar sx={{width:"40px !important",height:"40px !important"}}>
                                        <Icon icon={lstIcon?.[index]} color="primary"/>
                                      </Avatar>
                                  </Grid>
                                  <Grid item xs="12">
                                      <Typography as="subtitle2" color="primary">{item?.title?.[lang]}</Typography>
                                  </Grid>
                                  <Grid item xs="12" >
                                    <Typography as="body2">{item?.dec?.[lang]}</Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                          </Grid>
                          )
                        })
                    }
                    </Grid>
                    {/* <Grid item xs="12">
                        <Typography as="body1" px-3>{labels?.p2?.[lang]}</Typography>
                    </Grid>
                    <Grid item py-5 mx-auto>
                        <Button 
                            label={<Typography as="subtitle2" color={appColor.App_Beige_Color3}>{labels?.button?.[lang]}</Typography>}
                            onClick={()=>window?.open("https://www.al-sabahgroup.com/","_blank")}
                        />
                    </Grid> */}
                </Grid>
            </Container>
            </Grid>
          </Grid>
        </React.Fragment>
      )
}

export default JoinUs
