import React, { useEffect } from 'react'
import { Container, Grid, Typography } from '@cbmisorg/client-app'
import * as  appColor  from '../../../../../../appHelper/appColor'
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary'
import { appStyle } from '../../../../../../appHelper/appStyle'

function AlSabahFamily({lang}) {
  const labels = dictionary?.components?.public?.landingPage?.home?.AlsabahFamily

  useEffect(() => {
  }, [])

  return (
    <React.Fragment>
        <Container pt-10 width="fluid" id="alSabahFamily">
            <Grid container justify="center">
                <Grid item xs="12">
                      <Grid container justify="center">
                        <Grid item xs="12" md="10" lg="8" container spacing={3}>
                            <Grid item xs="12">
                              <Typography as="h4" sx={appStyle?.title1}>{labels?.title?.[lang]}</Typography>
                            </Grid>
                            <Grid item xs="12">
                                <Typography as="h6">{labels?.subtitle?.[lang]}</Typography>
                            </Grid>
                            <Grid item xs="12">
                                <Typography as="body1">{labels?.p1?.[lang]}</Typography>
                            </Grid>
                            <Grid item xs="12">
                                <Typography as="body1">{labels?.p2?.[lang]}</Typography>
                            </Grid>
                            <Grid item xs="12">
                                <Typography as="body1">{labels?.p3?.[lang]}</Typography>
                            </Grid>
                        </Grid>
                      </Grid>
                </Grid>
            </Grid>
        </Container>
    </React.Fragment>
  )
}

export default AlSabahFamily