import { Button, Card, CardDetails, CardImage, Container, Grid, Typography } from "@cbmisorg/client-app";
import React, { useState } from "react";
import { dictionary } from "../../../../../../appHelper/appLanguage/dictionary";
import { appStyle } from "../../../../../../appHelper/appStyle";
import { App_Download_URL } from "../../../../../../appHelper/appVariables";

const services1 = App_Download_URL + "services1.jpg";
const services2 = App_Download_URL + "services2.jpg";
const services3 = App_Download_URL + "services3.jpg";
const services4 = App_Download_URL + "services4.jpg";
const services5 = App_Download_URL + "services5.jpg";
const services6 = require("../../../../../../assets/images/services6.jpg"); // App_Download_URL + "services6.jpg";
const services7 = App_Download_URL + "services7.jpg";
const services8 = require("../../../../../../assets/images/services8.jpg"); // App_Download_URL + "services8.jpg";
const services9 = App_Download_URL + "services9.jpg";
const services10 = App_Download_URL + "services10.jpg";
const services11 = App_Download_URL + "services11.jpg";
const services12 = App_Download_URL + "services12.jpg";

const lstImg = [services3, services2, services1, services10, services5, services6, services8, services7, services4, services9, services11, services12];

export default function OurServices({ lang }) {
  const labels = dictionary?.components?.public?.landingPage?.home?.ourServices;
  const [state, setState] = useState({ openIndex: -1 });

  const openCard = (index) => () => {
    if (state?.openIndex !== index) {
      state.openIndex = index;
    } else {
      state.openIndex = -1;
    }
    setState({ ...state });
  };
  const closeCard = () => {
    if (state.openIndex !== -1) {
      state.openIndex = -1;
      setState({ ...state });
    }
  };

  return (
    <React.Fragment>
      <Grid container sx={appStyle?.container} py-10 my-8 id="ourServices" px-0 spacing={0}>
        <Grid item xs="12" container justify="center">
          <Container>
            <Grid container spacing={2} pb-5>
              <Grid item xs="12" sx={appStyle?.titleContainer} >
                <Typography as="h5" sx={appStyle?.title2} px-3>
                  {labels?.title?.[lang]}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs="12">
                <Typography as="body1" px-3>
                  {labels?.p1?.[lang]}
                </Typography>
              </Grid>
              <Grid item xs="12" container spacing={3} justify="center" alignSelf={"flex-start"}>
                {labels?.lst?.map((item, index) => {
                  return (
                    <Grid item xs="12" sm="6" lg="4" key={index} pb-10>
                      <Card onMouseLeave={closeCard}>
                        <CardImage src={lstImg?.[index]} className="cardTeamImg" loading="lazy" />
                        <CardDetails className="cardTeamDetails">
                          <Grid container sx={{ width: "100% !important" }}>
                            <Grid item xs="2" alignSelf="flex-start" px-0>
                              <Button icon={state?.openIndex === index ? "arrowCircleDown" : "arrowCircleUp"} p-1 mode="link" color="primary" size="sm" onClick={openCard(index)} />
                            </Grid>
                            <Grid item xs="10">
                              <Typography as="subtitle2" color="primary" sx={{ textAlign: "start !important" }}>
                                {item.title?.[lang]}
                              </Typography>
                            </Grid>
                            {state?.openIndex === index && (
                              <Grid item xs="12" pt-3>
                                <Typography as="body2">{item.point?.[lang]}</Typography>
                              </Grid>
                            )}
                          </Grid>
                        </CardDetails>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
