import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, AccordionTab, Container, Grid, Icon, Typography } from '@cbmisorg/client-app'
import * as  appColor  from '../../../../../../appHelper/appColor'
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary'
import { appStyle } from '../../../../../../appHelper/appStyle'

const img = require("../../../../../../assets/images/emaer.png")
const classes={
  img:{
    width:"100%",
    height:"100%"
  },
}
function EmirKuwait({lang}) {
  const labels = dictionary?.components?.public?.landingPage?.home?.emirKuwait

  return (
    <React.Fragment >
      <Container width="fluid" id="emirKuwait">
          <Grid container>
              <Grid item xs="12" lg="0" p-5 >
                <Typography as="h4" sx={appStyle?.title1}>{labels?.title?.[lang]}</Typography>
              </Grid>
              <Grid item xs="12" lg="5" alignSelf="flex-start" p-0 m-0>
                  <img src={img} alt="" style={classes?.img} loading='lazy'/>
              </Grid>
              <Grid item xs="12" lg="7"  container spacing="3" justify="center">
                  <Container>
                      <Grid container spacing={3}>
                          <Grid item xs="0" lg="12">
                            <Typography as="h4" sx={appStyle?.title1}>{labels?.title?.[lang]}</Typography>
                          </Grid>
                          <Grid item xs="12">
                              <Typography as="h6">{labels?.subtitle?.[lang]}</Typography>
                          </Grid>
                          <Grid item xs="12">
                              <Typography as="body1">{labels?.p1?.[lang]}</Typography>
                          </Grid>
                          <Grid item xs="12" className="accordionTab_landingPage" dir={lang==="arb"?"rtl":"ltr"}>
                            <Accordion mx-0>
                            {
                              labels?.lst?.map((item,index)=>{
                                return(
                                  <AccordionTab my-1 key={index}>
                                    <AccordionSummary expandIcon={<Icon icon="checkCircle" />}  py-3 px-3 sx={appStyle?.accordionSummary}>
                                      <Typography as="subtitle2" px-2 color={appColor?.App_Dark_Color}>{item?.title?.[lang]}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={appStyle?.accordionDetails}>
                                      <Typography as="body1">{item?.point?.[lang]}</Typography>
                                    </AccordionDetails>
                                  </AccordionTab>
                                  )
                              })
                            }
                            </Accordion>
                          </Grid>
                          <Grid item xs="12" lg="0">
                              <Typography as="body1">{labels?.p2?.[lang]}</Typography>
                          </Grid>
                      </Grid>
                  </Container>
              </Grid>
              <Grid item xs="0" lg="9" m-auto>
                  <Typography as="body1" sx={{textAlign:"center"}}>{labels?.p2?.[lang]}</Typography>
              </Grid>
          </Grid>
      </Container>
    </React.Fragment>
  )
}

export default EmirKuwait