import * as appVariables from "../../appVariables";
/**
 * @typedef {{ id?: number, bigMailID?: number, bigAppID?: number, bigSystemID?: number, bigTypeID?: number, bigPurposeID?: number, 
 * jsnMailInfo?: object, blnIsDeleted?: boolean, blnIsActive?: boolean, dtmCreatedDate?: string, dtmUpdatedDate?: string }} tblMailModule
 */

/**
 *
 * @param {{ objectInput: tblMailModule }} objInput
 */
export function AppAddMail(appState, objInput) {
  let appName = appVariables.App_Name;
  let tableName = "tblMail";
  let queryName = "aaiotAppAdd";

  let queryBody = `
      mutation{
        aaiotAppAdd(
          appInput:{
          strTableName: """${tableName}""",
          strObjectInput: """${JSON.stringify(objInput.objectInput)}"""
         }){
          blnIsResponseEncrypted
          blnIsRequestSuccessful
          strResponseType
          serverResponse
        }
      }`;

  return { appName, tableName, queryName, queryBody, appState };
}

/**
 *
 * @param {{ objectInput: tblMailModule, objectCondition: tblMailModule }} objInput
 */
export function gmsAppUpdate(appState, objInput) {
  let appName = appVariables.App_Name;
  let tableName = "tblMail";
  let queryName = "gmsAppUpdate";

  let queryBody = `
      mutation{
        gmsAppUpdate(
          appInput:{
          strTableName: """${tableName}""",
          strObjectInput: """${JSON.stringify(objInput.objectInput)}""",
          strObjectCondition: """${JSON.stringify(objInput.objectCondition)}"""           
         }){
          blnIsResponseEncrypted
          blnIsRequestSuccessful
          strResponseType
          serverResponse
        }
      }`;

  return { appName, tableName, queryName, queryBody, appState };
}

/**
 *
 * @param {{ objectCondition?: tblMailModule }} objInput
 */
export function gmsAppFindAll(appState, objInput) {
  let appName = appVariables.App_Name;
  let tableName = "tblMail";
  let queryName = "gmsAppFindAll";

  let queryBody = `
      query{
        gmsAppFindAll(
          appInput:{
          strTableName: """${tableName}""",
          strArrayOfStrAttributes: """${JSON.stringify(["bigTypeID", "bigPurposeID", "jsnMailBody", "dtmCreatedDate"])}""",
          ${objInput?.objectCondition ? `strObjectCondition: """${JSON.stringify(objInput.objectCondition)}"""` : ""}        
         }){
          blnIsResponseEncrypted
          blnIsRequestSuccessful
          strResponseType
          serverResponse
        }
      }`;

  return { appName, tableName, queryName, queryBody, appState };
}