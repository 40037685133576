
export const appLanguage = {
  arb: { blnActive: true, blnIsMandatory: true, label: "العربية" },
  eng: { blnActive: true, blnIsMandatory: true, label: "English" },
};

export const dictionary = {
  shared: {
    webInfo:{
      name1:{eng:"The private office of" , arb:"المكتب الخاص"},
      name2:{eng:"Sheikh Mohammed Al-Sabah" , arb:"لشيخ محمد الصباح"},
      group:{eng:"" , arb:"يمكنك زيارة مجموعة الدولية لشيخ محمد الصباح ومعرفة المزيد عن اعماله"},
      visitGroupBtn: { eng: "Visit Group", arb: "زيارة مجوعة الشيخ",},
    },
    stackHolder: {
      admin: { eng: "Admin", arb: "الادمن" },
      owner: { eng: "Owner", arb: "المالك" },
    },
    formValidationsMsg: {
      required: { eng: "Required!", arb: "الحقل مطلوب" },
      email: { eng: "The email not valid", arb: "البريد اللكتروني غير صحيح" },
      onlyNumber: { eng: "Only Number", arb: "مسموح فقط أرقام" },
    },
    alertFetchNote: {
      response_Not_Except: {
        eng: "An error has occurred, please use valid information and try again. If you keep getting the same error notification, please contact us via email.",
        arb: "حدث خطأ ، يرجى استخدام المعلومات الصحيحة والمحاولة مرة أخرى. إذا استمر ظهور نفس رسالة الخطأ ، فيرجى الاتصال بنا عبر البريد الإلكتروني.",
      },
      updated_Successfully: {
        eng: "Thank you for contacting us.  Your request has been received successfully, and we will contact you shortly",
        arb: "شكرا لإهتمامك. لقد تم إستلام طلبك بنجاح و سيتم التواصل معك قريبا",
      },
      added_Successfully: {
        eng: "Added Successfully",
        arb: "اضيف بنجاح",
      },
      cant_Update_Record: {
        eng: "Sorry, the update cannot be completed at this time. Please try again later.",
        arb: "عذرًا ، لا يمكن حفظ معلوماتك في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقًا.",
      },
      cant_Save_Record: {
        eng: "Sorry, can't save your information at this time. Please try again later.",
        arb: "عذرًا ، لا يمكن حفظ معلوماتك في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقًا.",
      },
      no_Data_To_Display: {
        eng: "Sorry, but there is no data to display yet.",
        arb: "عذرًا ، ولكن لا توجد بيانات لعرضها حتى الآن.",
      },
      fetchData_InProgress: {
        eng: "Your request has been successfully submitted and is being processed... please wait.",
        arb: "تم تقديم طلبك بنجاح وتتم معالجته ... يرجى الانتظار.",
      },
      invalidLoginInfo: {
        eng: "Some of your info is not correct. Please try again.",
        arb: "بعض المعلومات الخاصة بك غير صحيحة. يرجى المحاولة مرة أخرى.",
      },
      already_Registered: {
        eng: "You have already registered using this email address.",
        arb: "لقد قمت بالتسجيل بالفعل باستخدام عنوان البريد الإلكتروني هذا.",
      },
      email_Already_Used: {
        eng: "This email is already in use",
        arb: "هذا البريد استخدم من قبل",
      },
      email_CantSend: {
        eng: "Sorry, can't send your email at this time. Please try again later.",
        arb: "عذرًا ، لا يمكنني إرسال بريدك الإلكتروني في الوقت الحالي. الرجاء إعادة المحاولة لاحقًا.",
      },
      exceed_Limit_Of_Email: {
        eng: "Thank you. we received your email.",
        arb: "شكرا لك ، لقد تلقينا بريدك الإلكتروني.",
      },
      invalid_Field: {
        eng: "Please make sure all required fields are filled out correctly.",
        arb: "يرجى التأكد من ملء جميع الحقول المطلوبة بشكل صحيح.",
      },
      cantFindRecords: {
        eng: "Sorry, But there nothing to view.",
        arb: "عذرًا ، ولكن لا يوجد شيء لعرضه.",
      },
      cantUploadFile: {
        eng: "Sorry, But Can't upload file at this time please try again later.",
        arb: "عذرًا ، ولكن لا يمكن تحميل الملف في الوقت الحالي ، يرجى المحاولة مرة أخرى في وقت لاحق.",
      },
      contactUs: {
        eng: "شكرا لتواصلكم مع المكتب الخاص للشيخ محمد الصباح. لقد تم استلام رسالتكم وسنقوم بالرد عليكم قريبا",
        arb: "Thank you for contacting the private office of Sheikh Mohammed Al-Sabah. Your message has been received and we will respond promptly",
      },
    },
  },
  components: {
    public: {
      landingPage:{
        home: {
          hero:{},
          AlsabahFamily:{
            title:{eng:"Al-Sabah Family History",arb:"تاريخ عائلة الصباح"},
            subtitle:{
              eng:"Al-Sabah family is considered one of the oldest and most important rulers in the Arab world",
              arb:"عائلة الصباح واحدة من أقدم وأهم العائلات الحاكمة في العالم العربي"},
            p1:{
                eng:"As they established the preservation of the independence of the State of Kuwait over the past decades. Al-Sabah family has a long history and great legacy in directing Kuwait’s financial and economic matters, and has led it through multiple challenges and successes.",
                arb:"حيث قامت بتأسيس وحفظ استقلال دولة الكويت على مدى العقود الماضية. تتمتع هذه العائلة بتاريخ طويل وإرث عظيم في توجيه الأمور السياسية والإقتصادية للكويت، وقادتها خلال فترات تحديات ونجاحات متعددة. "},
            p2:{
              eng:"Sheikh Ahmad Al-Sabah is the main patriot of the State of Kuwait. In 1752, he led forces from Najd to the Kuwait region and contributed to establish a settlement there. Thanks to his business skill and understanding with the local countries, Sheikh Ahmad was able to unite Kuwait under his authority. Fourteen rulers from the Al-Sabah family assumed the rule of the State of Kuwait from its inception until the present, the last of whom was Sheikh Nawaf Al-Ahmad Al-Sabah, may God protect him.",
              arb:"الشيخ أحمد الصباح هو المؤسس الأساسي لدولة الكويت الحديثة. في العام 1752، قاد أحمد الصباح قواتٍ من نجد إلى منطقة الكويت وساهم في تأسيس مستوطنة هناك. بفضل مهارته السياسية وتفاهمه مع القبائل المحلية، تمكن أحمد الصباح من توحيد الكويت تحت سلطته وتأسيس نواة للدولة. تولي حكم دولة الكويت منذ نشأتها حتى الوقت الحالي أربعة عشر حاكمًا من عائلة الصباح كان اخرهم الشيخ نواف الأحمد الصباح حفظه الله."}
          },
          emirKuwait:{
            title:{eng:"Emir of Kuwait",arb:"أمير دولة الكويت"},
            subtitle:{eng:"H.H Sheikh Mishal Al-Ahmad Al-Sabah",arb:"سمو الشيخ مشعل الأحمد الصباح"},
            p1:{eng:"His Highness Sheikh Meshaal Al-Sabah assumed the position of Emir of the State of Kuwait in December 2023 after the death of Sheikh Nawaf Al-Sabah, and he has been able to achieve continuous achievements over many years:",
                arb:"تولى سمو الشيخ مشعل الصباح منصب أمير دولة الكويت في ديسمبر 2023 بعد وفاة الشيخ نواف الصباح، وقد قام بالعديد من الإنجازات والإجراءات الهامة خلال السنوات الماضية:"},
            lst:[
              {
                title: {eng:"Supporting the Economy",arb:"دعم الإقتصاد"},
                point: {eng:"Sheikh Mishal began implementing an ambitious plan to promote diversification of the economy, reduce dependence on oil, and attract foreign investments.",arb:"بدأ الشيخ مشعل في تنفيذ خطة ذكية طموحة لتعزيز التنويع في تنويع الإقتصاد وتقليل الإعتماد على عائدات النفط وجذب الاستثمارات الأجنبية."},
              },
              {
                title: {eng:"Supporting human rights and public freedoms",arb:"دعم حقوق الإنسان والحريات العامة"},
                point: {eng:"Sheikh Mishal began promoting human rights and public freedoms in Kuwait by developing laws and regulations to enhance societal contribution and women's rights.",arb:"بدأ الشيخ مشعل في تعزيز حقوق الإنسان والحريات العامة في الكويت من خلال تطوير القوانين واللوائح لتعزيز المشاركة المجتمعية وتعزيز حقوق المرأة والشباب."},
              },
              {
                title: {eng:"Development of the national guard",arb:"تطوير الحرس الوطني"},
                point: {eng:"During the reign of Sheikh Mishal the Office of the Deputy Chief of the National Guard received the 'Jaber Quality Award' and the 'ISO Certificate'.",arb:"في عهد الشيخ نواف حصل ديوان نائب رئيس الحرس الوطني على 'جائزة جابر للجودة' و'شهادة الآيزو'."},
              },
              {
                title: {eng:"Update the state security agency",arb:"تحديث جهاز أمن الدولة"},
                point: {eng:"Sheikh Mishal Al-Ahmad had clear contributions to the development of the State Security Apparatus, as he was keen on its improvement and the enhancement of its members' capabilities.",arb:"كان للشيخ مشعل الأحمد بصمات واضحة في تطوير جهاز أمن الدولة، حيث حرص على النهوض به وبقدرات أعضائه."},
              },
              {
                title: {eng:"Regional and international role",arb:"الدور الإقليمي والدولي"},
                point: {eng:"Sheikh Mishal held important diplomatic positions before ascending to the throne, which contributed to enhancing Kuwait's role on the regional and international stages. Kuwait has sought to strengthen cooperation with sisterly and friendly countries in various fields.",arb:"شغل الشيخ نواف مناصب دبلوماسية هامة قبل توليه العرش، وهذا ساهم في تعزيز دور الكويت على الساحة الإقليمية والدولية. سعت الكويت إلى تعزيز التعاون مع الدول الشقيقة والصديقة في مختلف المجالات."},
              },

            ],
          },
          visionofKuwait:{
            title:{eng:"Unified Direction for a Sustainable Future",arb:"توجه موحد نحو مستقبل مزدهر ومستدام"},
            subtitle:{eng:"Kuwait Vision 2035",arb:"رؤية الكويت  2035"},
            p1:{eng:"Kuwait Vision 2035 is an ambitious development and economic strategy that aims to achieve a comprehensive transformation in multiple areas to ensure the sustainability of development and the well-being of citizens in the future. This vision represents part of Kuwait's efforts to achieve sustainable development and economic diversification. Here is an overview of the highlights of the New Kuwait Vision:",arb:"رؤية الكويت 2035 هي إستراتيجية تنموية وإقتصادية طموحة تهدف إلى تحقيق تحول شامل في مجالات متعددة لضمان إستدامة التنمية ورفاهية المواطنين في المستقبل. تمثل هذه الرؤية جزءاً من جهود الكويت لتحقيق التنمية المستدامة والتنويع الإقتصادي. فيما يلي نظرة عامة على أبرز معالم رؤية الكويت الجديدة:"},
            p2:{eng:"Kuwait Vision 2035 represents great optimism for the future of Kuwait, as it aims to achieve sustainable development and achieve a balance between the economic, social and environmental aspects. This vision represents a commitment to achieving progress and the well-being of citizens, and is part of Kuwait's efforts to achieve long-term sustainable development goals.",arb:"رؤية الكويت 2035 تمثل تفاؤلاً كبيراً بمستقبل الكويت، حيث تهدف إلى تحقيق التنمية المستدامة وتحقيق التوازن بين الجوانب الإقتصادية والإجتماعية والبيئية. تمثل هذه الرؤية التزاماً بتحقيق التقدم ورفاهية المواطنين، وهي جزء من جهود الكويت لتحقيق أهداف تنمية مستدامة على المدى الطويل."},
            lst:[
              {
                title: {eng:"Diversifying the economy",arb:"تنويع الإقتصاد"},
                point: {eng:"The vision aims to reduce Kuwait’s dependence on oil as a main source of national revenues. This is done by strengthening other sectors such as the financial sector, investment, industry and technology.",arb:"تهدف الرؤية إلى تقليل إعتماد الكويت على النفط كمصدر رئيسي للإيرادات الوطنية. وذلك من خلال تعزيز القطاعات الأخرى مثل القطاع المالي والاستثمار والصناعة والتكنولوجيا."},
              },
              {
                title: {eng:"Developing education",arb:"تطوير التعليم والبحث العلمي"},
                point: {eng:"The vision seeks to improve the quality of education and enhance scientific research and technological development to qualify national cadres and increase their creativity and excellence.",arb:"تسعى الرؤية إلى تحسين جودة التعليم وتعزيز البحث العلمي والتطوير التكنولوجي لتأهيل الكوادر الوطنية وزيادة إبداعهم وتفوقهم."},
              },
              {
                title: {eng:"Enhancing investment climate",arb:"تعزيز البيئة الاستثمارية"},
                point: {eng:"It aims to improve the investment environment, provide support to small and medium enterprises, and increase Kuwait’s attractiveness for national and foreign investment.",arb:"تهدف إلى تحسين بيئة الاستثمار وتقديم الدعم للمشاريع الصغيرة والمتوسطة وزيادة جاذبية الكويت للاستثمار الوطني والأجنبي."},
              },
              {
                title: {eng:"Healthcare improvement",arb:"تحسين الخدمات الصحية"},
                point: {eng:"The vision seeks to improve health infrastructure and the quality of health care provided to citizens.",arb:"تسعى الرؤية إلى تحسين البنية التحتية الصحية وجودة الرعاية الصحية المقدمة للمواطنين."},
              },
              {
                title: {eng:"Promoting technology",arb:"تعزيز التكنولوجيا والابتكار"},
                point: {eng:"It aims to enhance technological research and development and encourage innovation in various sectors.",arb:"تهدف إلى تعزيز البحث والتطوير التكنولوجي وتشجيع الابتكار في مختلف القطاعات."},
              },
              {
                title: {eng:"National projects",arb:"المشروعات الوطنية"},
                point: {eng:"Kuwait Vision 2035 includes many major national projects that aim to enhance development and the economy and improve the quality of life. These projects include the Kuwaiti Sabah City project, the Failka Residential Island project, and the new Kuwait International Airport project.",arb:"رؤية الكويت 2035 تشمل العديد من المشروعات الوطنية الكبرى التي تهدف إلى تعزيز التنمية والإقتصاد وتحسين جودة الحياة. من هذه المشروعات مشروع مدينة الصباح الكويتية ومشروع جزيرة فيلكا السكنية ومشروع مطار الكويت الدولي الجديد."},
              },
              {
                title: {eng:"Economic rewards",arb:"مكافآت إقتصادية"},
                point: {eng:"Kuwait Vision 2035 includes a set of economic rewards for citizens aimed at stimulating effective participation in national development. These rewards include distributing oil profits, providing jobs, and providing housing and health care.",arb:"تشمل رؤية الكويت 2035 مجموعة من المكافآت الإقتصادية للمواطنين تهدف إلى تحفيز المشاركة الفعالة في التنمية الوطنية. تشمل هذه المكافآت توزيع الأرباح النفطية وتوفير فرص العمل وتوفير الإسكان والرعاية الصحية."},
              },
              {
                title:{eng:"Global engagement",arb:"مشاركة فعالة في المجتمع العالمي"},
                point:{eng:"Global engagement, enhancing the position of the State of Kuwait regionally and internationally in the fields of diplomacy, trade and cultural exchange, and charitable work.",arb:"مشاركة فعالة في المجتمع العالمي، تعزيز مكانة دولة الكويت إقليميا ودولياً في المجالات الدبلوماسية، والتبادل التجاري والثقافي، والعمل الخيري."},
              }
            ],

          },
          messageofSheikhMuhammad:{
            title:{eng:"Sheikh Mohammed Ahmed Al-Sabah",arb:"الشيخ محمد أحمد الصباح"},
            subtitle:{eng:"Message from Chairman",arb:"رسالة رئيس مجلس الإدارة"},
            quote:{eng:"I believe that our strength lies in uniting our efforts to achieve this transformation. Therefore, we always ensure the appropriate environment and full support for everyone who wishes to contribute to this transformation.",arb:"إنني أؤمن بأن قوتنا تكمن في توحيد جهودنا لتحقيق هذا التحول لذلك نحن نحرص دوما على البيئة المناسبة والدعم الكامل لكل من يرغب في المساهمة في هذا التحول."},
            p1:{eng:"Our current era has witnessed tremendous technological development, as technology has become an essential part of our daily lives. In this context, we find that digital transformation has become an inevitable necessity because of its profound impact on several aspects of our lives:",arb:"لقد شهد عصرنا الحالي تطورًا تكنولوجيًا هائلًا، حيث أصبحت التكنولوجيا جزءًا أساسيًا من حياتنا اليومية. وفي هذا السياق، نجد أن التحول الرقمي أصبح ضرورة حتمية لما له من تأثير عميق على عدة جوانب في حياتنا:"},
            lst:[
              {
                title:{eng:"Economy",arb:"الإقتصاد "},
                point:{eng:"Digital transformation can further diversify our economy and foster innovation and leadership in a variety of industries. This can create new job opportunities and increase investment in our country.",arb:"يمكن أن يعزز التحول الرقمي من تنويع إقتصادنا وتعزيز الابتكار والريادة في مجموعة متنوعة من الصناعات. يمكن أن يؤدي ذلك إلى خلق فرص عمل جديدة وزيادة الاستثمار في بلادنا."}
              },
              {
                title:{eng:"Improving government services",arb:"تحسين الخدمات الحكومية "},
                point:{eng:"Digital transformation can improve the quality of government services and make them more effective and easier for citizens. It will allow us to access government services more quickly and easily.",arb:"يمكن للتحول الرقمي تحسين جودة الخدمات الحكومية وجعلها أكثر فعالية وسهولة للمواطنين. سيتيح لنا الوصول إلى الخدمات الحكومية بسرعة وسهولة أكبر."}
              },
              {
                title:{eng:"Improving environmental quality",arb:"تحسين الجودة البيئية "},
                point:{eng:"By adopting environmental technology, we can reduce pollution and improve the environmental quality of our country.",arb:"من خلال تبني التكنولوجيا البيئية، يمكننا الحد من التلوث وتحسين جودة البيئة في وطننا."}
              },
              {
                title:{eng:"Education and knowledge",arb:"التعليم والمعرفة "},
                point:{eng:"Digital transformation will enable us to provide online teaching and learning opportunities and increase access to knowledge.",arb:" سيمكننا التحول الرقمي من توفير فرص التعليم والتعلم عبر الإنترنت وزيادة إمكانية الوصول إلى المعرفة."}
              },
              {
                title:{eng:"Improving the health sector",arb:"تحسين القطاع الصحي "},
                point:{eng:"Digital transformation can improve the health care system and make it more efficient. Technology can also be used to facilitate access to medical information and improve the delivery of health services.",arb:"يمكن للتحول الرقمي تحسين نظام الرعاية الصحية وجعله أكثر كفاءة. يمكن استخدام التكنولوجيا لتسهيل الوصول إلى المعلومات الطبية وتحسين تقديم الخدمات الصحية."}
              },
              {
                title:{eng:"Enhanced security and privacy",arb:"تعزيز الأمان والخصوصية "},
                point:{eng:"We will have the opportunity to enhance cybersecurity and protect our personal data by adopting the latest technologies and practices.",arb:"سيكون لدينا فرصة لتعزيز الأمان السيبراني وحماية بياناتنا الشخصية من خلال تبني أحدث التقنيات والممارسات."}
              },
              {
                title:{eng:"The role of youth",arb:"دور الشباب "},
                point:{eng:"Youth can play a crucial role in digital transformation. Youth skills in technology can be enhanced and encouraged to submit ideas and participate in innovation projects.",arb:"يمكن للشباب أن يلعبوا دورًا حاسمًا في التحول الرقمي. يمكن تعزيز مهارات الشباب في مجالات التكنولوجيا وتشجيعهم على تقديم الأفكار والمشاركة في مشاريع الابتكار."}
              },
              {
                title:{eng:"Communication and transparency",arb:"التواصل والشفافية "},
                point:{eng:"Digital transformation can enhance communication between the government and citizens and increase the level of transparency in decision-making and government management.",arb:"يمكن للتحول الرقمي تعزيز التواصل بين الحكومة والمواطنين وزيادة مستوى الشفافية في اتخاذ القرارات وإدارة الحكومة."}
              },
              {
                title:{eng:"Improving infrastructure",arb:"تحسين البنية التحتية "},
                point:{eng:"Investment must be made in developing technological infrastructure to ensure the provision of fast and reliable communications throughout the country.",arb:"يجب الاستثمار في تطوير البنية التحتية التكنولوجية لضمان توفير الاتصالات السريعة والموثوقة في جميع أنحاء البلاد."}
              },
              {
                title:{eng:"Sustainability",arb:"الاستدامة "},
                point:{eng:"Digital transformation can enhance the concept of sustainability by directing our efforts towards using technology to preserve environmental resources and reduce waste.",arb:"يمكن للتحول الرقمي تعزيز مفهوم الاستدامة من خلال توجيه جهودنا نحو استخدام التكنولوجيا للحفاظ على الموارد البيئية وتقليل الإهدار."}
              },
            ]
          },
          ourGoal:{
            title:{eng:"Our Goals",arb:"أهدافنا"},
            p1:{eng:"Our goals in adopting modern technology deal with achieving sustainable progress and improvement in multiple areas, starting from empowering young people and getting them on the path to education and access to better opportunities, all the way to supporting the development of companies and enhancing the efficiency of governments. Here are some of the goals we strive to achieve:",arb:"أهدافنا في تبني التكنولوجيا الحديثة تتعامل مع تحقيق تقدم مستدام وتحسين في مجالات متعددة، بدءًا من تمكين الشباب ومضيهم في مسار التعليم والوصول إلى فرص أفضل، وصولًا إلى دعم تطوير الشركات وتعزيز كفاءة الحكومات:"},
            lst:[
              {
                title: {eng:"Youth empowerment",arb:"تمكين الشباب"},
                point: {eng:"Providing young people with modern technical skills and digital education to increase job opportunities and develop their skills.",arb:"تزويد الشباب بالمهارات التقنية الحديثة والتعليم الرقمي لزيادة فرص العمل وتطوير مهاراتهم."},
              },
              {
                title: {eng:"Entrepreneurship support",arb:"دعم الابتكار وريادة الأعمال"},
                point: {eng:"Providing advice and support to startups and SMEs to stimulate innovation and business growth.",arb:"تقديم المشورة والدعم للشركات الناشئة والمشروعات الصغيرة والمتوسطة لتحفيز الابتكار ونمو الأعمال."},
              },
              {
                title: {eng:"Designing smart platforms",arb:"تصميم منصات  تقنية"},
                point: {eng:"providing infrastructure for technical innovation and developing new ideas.",arb:"توفير بنية تحتية للابتكار التقني وتطوير الأفكار الجديدة."},
              },
              {
                title: {eng:" Improving government efficiency",arb:"تحسين كفاءة الحكومات"},
                point: {eng:"Providing integrated technological solutions to improve government performance and improve the provision of government services to citizens.",arb:"تقديم حلول تكنولوجية متكاملة لتحسين أداء الحكومات وتحسين تقديم الخدمات الحكومية للمواطنين."},
              },
              {
                title: {eng:"Promoting distance learning",arb:"تعزيز التعلم عبر الإنترنت والتعليم عن بعد"},
                point: {eng:"Providing high-quality online education platforms to provide educational opportunities available to everyone at any time and from anywhere.",arb:"توفير منصات تعليم عبر الإنترنت عالية الجودة لتوفير فرص تعليمية متاحة للجميع في أي وقت وفي أي مكان."},
              },
              {
                title: {eng:"Increasing tech-awareness",arb:"زيادة الوعي التكنولوجي"},
                point: {eng:"Providing awareness campaigns about the importance of technology, artificial intelligence, cybersecurity, virtual reality, and providing training courses and educational workshops to the community to enhance technological awareness.",arb:"تقديم حملات توعية حول أهمية التكنولوجيا والذكاء الإصطناعي والأمان السيبراني و تقديم دورات تدريبية وورش عمل تعليمية للمجتمع لتعزيز الوعي التكنولوجي."},
              },
              {
                title: {eng:"Empowering women",arb:"دعم المرأة في مجال التكنولوجيا"},
                point: {eng:"Promoting the effective participation of women in the technology industry by providing training and educational programs and employment opportunities and empowering them to contribute to technological innovation.",arb:"تعزيز المشاركة الفعالة للنساء في صناعة التكنولوجيا من خلال توفير برامج تدريبية وتعليمية وفرص توظيف وتمكينهن للمساهمة في الابتكار التكنولوجي "},
              },
              {
                title: {eng:"Offer technological services",arb:"توفير خدمات تكنولوجية متكاملة"},
                point: {eng:"Providing innovative business models based on the company’s concept as a service, where technical services are provided to individuals and companies of all sizes at a reasonable cost and without the need for significant investment in infrastructure or application design.",arb:"تقديم نماذج أعمال مبتكرة تعتمد على مفهوم الشركة كخدمة، حيث يتم توفير الخدمات التقنية للأفراد والشركات من جميع الأحجام بتكلفة معقولة وبدون حاجة للاستثمار الكبير في البنية التحتية أو التطبيقات."},
              },
              {
                title: {eng:"Digital transformation",arb:" تعزيز التحول الرقمي على كافة مستويات الدولة"},
                point: {eng:"Providing our technical services under the umbrella of “Company as a Service” is one of our most important goals adopted in order to achieve technical empowerment for individuals, companies, and government sectors. This concept also contributes to reaching integrated and modern technical solutions in an easy and low cost.",arb:"تقديم خدماتنا التقنية تحت مظلة الشركة كخدمة لتحقيق التمكين التقني للأفراد والشركات والقطاعات الحكومية كما ويساهم هذا المفهوم  في الوصول إلى حلول تقنية متكاملة وحديثة بشكل ميسر وبتكلفة منخفضه"},
              },
            ],
            p2:{eng:"Providing our technical services under the umbrella of “Company as a Service” is one of our most important goals adopted in order to achieve technical empowerment for individuals, companies and government sectors. This concept also contributes to reaching integrated and modern technical solutions in an easy and low cost, which contributes to Digital transformation. ",arb:"يعد تقديم خدماتنا التقنية تحت مظلة الشركة كخدمة من أهم أهدافنا المتبناه من أجل تحقيق التمكين التقني للأفراد والشركات والقطاعات الحكومية كما ويساهم هذا المفهوم أيضا في الوصول إلى حلول تقنية متكاملة وحديثة بشكل ميسر وبتكلفة منخفضه، مما يسهم في تعزيز التحول الرقمي على كافة مستويات الدولة. "}

          },
          ourExperiences:{
            title:{eng:"Our Experiences",arb:"خبراتنا"},
            p1:{eng:"Our expertise varies to include providing integrated solutions that ensure the success of digital transformation at the level of private companies and various government sectors in order to help them:",arb:"تتنوع خبراتنا لتشمل تقديم حلول متكاملة تضمن نجاح التحول الرقمي على مستوى الشركات الخاصه والقطاعات الحكومية المختلفة من أجل مساعدتهم على:"},
            p2:{eng:"In short, our integrated solutions will help companies and government departments achieve high efficiency, provide added value to customers and partners, and achieve better growth and sustainability. To learn more about these solutions, please visit our group website.",arb:" بإختصار, سوف تساعد حلولنا المتكاملة مؤسستك أو دائرتك على تحقيق كفاءة عالية وتقديم قيمة مضافة للعملاء والشركاء وتحقيق نمو واستدامة أفضل. للتعرف أكثر على هذه الحلول وتفاصيلها الرجاء زيارة موقع المجموعة"},
            button:{eng:"Visit our Group Website",arb:"زيارة موقع المجموعة"},
            lst:[
              {
                title:{eng:"Improving productivity efficiency",arb:"تحسين الكفاءة والإنتاجية"},
                point:{eng:"Our technology solutions provide a set of integrated and consistent tools that facilitate building applications of all types, managing their users, and unifying systems, which ensures saving time and effort to create and manage them.",arb:" تقدم حلولنا التكنولوجية المتكاملة أدوات متكاملة ومتناسقة تسهل بناء كافة انواع التطبيقات وإدارة مستخدميها و التعامل مع مجموعة متنوعة من الأنشطة وتوحيد الأنظمة مما يضمن توفير الوقت والجهد لإنشائها وإدارتها"},
              },
              {
                title:{eng:"Increase data accuracy",arb:"زيادة دقة البيانات والتحليل"},
                point:{eng:"We help improve the accuracy of data collection, facilitate analysis, and make better decisions based on it.",arb:"نساعد في تحسين دقة تجميع البيانات وتيسير عمليات التحليل واتخاذ القرارات الأفضل بناءا عليها."},
              },
              {
                title:{eng:"Exceptional user experiences",arb:"تقديم تجارب مستخدم متميزة"},
                point:{eng:"Helping organizations achieve better interaction with customers and beneficiaries and meet their expectations across various devices and channels.",arb:"تساعد في تحقيق تفاعل أفضل مع العملاء والمستفيدين وتلبية توقعاتهم عبر مختلف الأجهزة والقنوات."},
              },
              {
                title:{eng:"Reducing costs and risks",arb:"تقليل التكاليف والمخاطر"},
                point:{eng:"improving resource management, improving their use with better efficiency, reducing maintenance and modernization costs, and contributing to reducing security and compliance risks.",arb:"تحسين إدارة الموارد وتحسين استخدامها بكفاءة أفضل والتقليل من تكاليف الصيانة والتحديث وتساهم في تقليل مخاطر الأمان والامتثال."},
              },
              {
                title:{eng:"Achieving competitiveness",arb:"تحقيق التنافسية"},
                point:{eng:"improving the work organization of institutions and companies by exploiting technology to provide distinguished services and products that ensure competitiveness in local and global markets.",arb:"تحسين تنظيم عمل المؤسسات والشركات واستغلال التكنولوجيا لتقديم خدمات ومنتجات متميزة تضمن تحقيق التنافسية في الأسواق المحلية والعالمية."},
              },
              {
                title:{eng:"Facilitating expansion and growth",arb:"تيسير التوسع والنمو"},
                point:{eng:"managing sustainable growth and harnessing technology to effectively overcome the complexities of expansion and enter new markets.",arb:"إدارة النمو المستدام وتسخير التكنولوجيا للتغلب على تعقيدات التوسع ودخول أسواق جديدة بفعالية."},
              },
            ]
          },
          ourServices:{
            title:{eng:"Our Services",arb:"خدماتنا"},
            p1:{eng:"Our integrated services include everything any organization or programmer needs in order to develop smart and secure applications and platforms that contribute to simplifying, improving, and making all the services provided more effective. Below is a summary about our services:",arb:"تشمل خدماتنا المتكاملة كل ما تحتاجة أي شركة أو مؤسسة  من أجل تطوير تطبيقات ومنصات ذكية وأمنة تساهم في تبسيط جميع الخدمات المقدمة وتحسنها وجعلها أكثر فعالية. من الأمثلة على خدماتنا المتكاملة:"},
            p2:{eng:"Making good use of these services can contribute to successfully achieving digital transformation for companies and institutions without the need to pay exorbitant costs or risk not completing huge projects before the end of the specified time.",arb:"أن الاستفادة الجيدة من هذه الخدمات يمكن أن يساهم في تحقيق التحول الرقمي بنجاح للشركات والمؤسسات. "},
            lst:[
              {
                title:{eng:"Consultations",arb:"تقديم الإستشارات"},
                point:{eng:"Providing technical and strategic consultations to develop a digital transformation plan that suits the organization’s goals and the needs of all its users.",arb:"تقديم استشارات تقنية واستراتيجية للشركات لوضع خطة تحول رقمي تتناسب مع أهدافها واحتياجاتها."},
              },
              {
                title:{eng:"Professional training",arb:"التدريب وتطوير الموارد البشرية"},
                point:{eng:"Providing training and development programs for employees to increase their skills and understanding of new technology.",arb:"تقديم برامج تدريبية وتطويرية للموظفين لزيادة مهاراتهم وفهمهم للتكنولوجيا الجديدة."},
              },
              {
                title:{eng:"Software development",arb:"تطوير البرمجيات"},
                point:{eng:" Providing application development services and technology necessary to implement the digital transformation strategy and assist in designing applications at the lowest cost and time.",arb:"تقديم خدمات تطوير التطبيقات والتكنولوجيا اللازمة لتنفيذ استراتيجية التحول الرقمي."},
              },
              {
                title:{eng:"Cloud and infrastructure",arb:"السحابة والبنية التحتية"},
                point:{eng:"Providing cloud and infrastructure services to companies to improve the sustainability of technological systems.",arb:"تقديم خدمات السحابة والبنية التحتية للشركات لتحسين استدامة الأنظمة التكنولوجية."},
              },
              {
                title:{eng:"Project management",arb:"إدارة المشاريع والتنفيذ"},
                point:{eng:"Providing project management services to help implement digital transformation projects successfully and through a single platform that connects all those working on designing the application.",arb:"تقديم خدمات إدارة المشاريع للمساعدة في تنفيذ مشاريع التحول الرقمي بنجاح."},
              },
              {
                title:{eng:"Analytics and Big Data",arb:"التحليلات والبيانات الضخمة"},
                point:{eng:"Help companies exploit data to extract valuable insights and make informed decisions.",arb:"مساعدة الشركات في استغلال البيانات لاستخراج رؤى قيمة واتخاذ قرارات مستنيرة."},
              },
              {
                title:{eng:"Information Security",arb:"أمن المعلومات"},
                point:{eng:"Providing solutions to enhance information security and compliance with applicable laws and regulations.",arb:"تقديم حلول لتعزيز أمان المعلومات والامتثال باللوائح والقوانين السارية."},
              },
              {
                title:{eng:"Artificial Intelligence",arb:"التعلم الآلي والذكاء الاصطناعي"},
                point:{eng:"Helping companies implement solutions based on Artificial Intelligence to improve performance and achieve prediction.",arb:"مساعدة الشركات في تنفيذ حلول تعتمد على التعلم الآلي والذكاء الاصطناعي لتحسين الأداء وتحقيق التنبؤ."},
              },
              {
                title:{eng:"Internet of Things",arb:"انترنت الأشياء"},
                point:{eng:"Providing integrated solutions that allow companies to connect their devices and systems to the Internet to collect and analyze data and provide platforms for remote monitoring and control of these devices in order to improve efficiency and increase productivity.",arb:"تقديم حلول متكاملة تتيح للشركات ربط أجهزتها وأنظمتها بالإنترنت لجمع البيانات وتحليلها وتوفير منصات للمراقبة والتحكم عن بعد في هذه الأجهزه من أجل تحسين الكفاءة وزيادة الإنتاجية."},
              },
              {
                title:{eng:"Metaverse World",arb:"عالم الميتافيرس"},
                point:{eng:"Providing technical solutions to build and host virtual worlds and develop high-quality content to serve the education sector (by creating virtual educational environments that allow students to interact with educational materials in ways that make learning more enjoyable and effective) and the business sector (by helping institutions establish a virtual presence Within the metaverse to reach new customers and increase its fame and revenues)",arb:"تقديم حلول تقنية لبناء واستضافة العوالم الافتراضية و تطوير محتوى ذو جودة عالية لخدمة قطاع التعليم (من خلال أنشاء بيئات تعليمية افتراضية واقعية تسمح للطلاب بالتفاعل مع المواد التعليمية بطرق تجعل التعلم أكثر متعة وفعالية) وقطاع الأعمل (من خلال إقامة وجود افتراضي داخل الميتافيرس للوصول إلى عملاء جدد وزيادة شهرتها وإيراداتها)"},
              },
              {
                title:{eng:"Technical support",arb:"الدعم الفني وخدمات ما بعد التنفيذ"},
                point:{eng:"Providing continuous technical support and maintenance to ensure the continuity and efficiency of the systems.",arb:"توفير دعم فني متواصل وصيانة للتأكد من استمرارية الأنظمة والتكنولوجيا."},
              },
            ]
          },
          initiatives:{
            title:{eng:"Our Initiatives",arb:"مبادراتنا"},
            p1:{eng:"Technology is the backbone of progress in the world today, but its success and sustainability require good integration between multiple sectors and enhancing workforce diversity. Hence our innovative initiatives aimed at encouraging interaction between different technology sectors and supporting women’s participation in different technology fields.",arb:"إن التكنولوجيا هي عمود فقري التقدم في العالم اليوم، ولكن نجاحها واستدامتها يتطلبان الدمج الجيد بين قطاعات متعددة وتعزيز تنوع القوى العاملة. من هنا جاءت مبادراتنا المبتكرة التي تهدف إلى تشجيع التفاعل بين قطاعات التكنولوجيا المختلفة ودعم مشاركة المرأة القوية في هذا المجال."},
            p2:{eng:"We are committed to building a more diverse and prosperous technology community and invite everyone to join our efforts to achieve this important goal. The future of technology depends on collaboration and empowerment, and we are working hard to achieve it. The benefits of these initiatives:",arb:"نحن بدورنا ملتزمون ببناء مجتمع تقني أكثر تنوعًا وازدهارًا و ندعو الجميع للانضمام إلى جهودنا لتحقيق هذا الهدف المهم. إن مستقبل التكنولوجيا يعتمد على التعاون والتمكين، ونحن نعمل بجد لتحقيقه. فوائد هذه المبادرات:"},
            initiativelst:[
              {
                title:{eng:"Conference on (The importance of technological integration on the educational sector and the business sector)",
                arb:"مؤتمر أهمية الدمج التكنولوجي على القطاع التعليمي وقطاع الأعمال"},
                dec:{eng:"The conference aims to promote technological integration. This conference will be an ideal occasion for specialists in different fields of technology to meet and share ideas and experiences. Through lectures and interactive sessions, we will highlight the importance of interaction between technology sectors and how this can contribute to innovation, curriculum development and training.",arb:"يهدف المؤتمر إلى تعزيز الدمج التكنولوجي. سيكون هذا المؤتمر مناسبة مثالية المختصين في مجالات التكنولوجيا المختلفة للالتقاء ومشاركة الأفكار والتجارب. من خلال المحاضرات والجلسات التفاعلية، سنسلط الضوء على أهمية التفاعل بين قطاعات التكنولوجيا وكيف يمكن أن يسهم ذلك في تحقيق الإبتكار وتطوير المناهج الدراسية والتدريب."},
                dateStart:{eng:"The conference registration date will be announced soon",arb:"سيتم الإعلان قريبا عن موعد بدء التسجيل في المؤتمر"},
                blnHaveWebsite:false
              },
              {
                title:{eng:"Sheikh Mohammed Al-Sabah Group initiative to train 100 Arab programmers",arb:"مبادرة مجموعة الشيخ محمد الصباح لتدريب 100 مبرمجة عربية"},
                dec:{eng:"Women are an important player in the technology industry, and we strive to increase their participation in this field. For this reason, we launched an initiative to empower Arab female programmers. This initiative aims to train and empower 100 young women from various backgrounds to become professional programmers.",arb:"تعتبر المرأة لاعباً مهماً في صناعة التكنولوجيا، ونحن نسعى جاهدين لزيادة مشاركتها في هذا المجال. لهذا السبب، أطلقنا مبادرة لتمكين المبرمجات العربيات. هذه المبادرة تستهدف تدريب وتمكين 100 إمرأة شابة من مختلف الخلفيات ليصبحن مبرمجات محترفات. "},
                dateStart:{eng:"The training registration date will be announced soon",arb:"سيتم الإعلان قريبا عن موعد التسجيل في دورة البرمجة المتكاملة"},
                blnHaveWebsite:true
              },
            ],
            button:{eng:"Join training",arb:"انضم لتدريب"},
            

            lst:[
              {eng:"Enhancing interaction between technology sectors to achieve innovation.",arb:"تعزيز التفاعل بين قطاعات التكنولوجيا لتحقيق الابتكار"},
              {eng:"Increase women’s participation in the technology industry and empower them.",arb:"زيادة مشاركة المرأة في صناعة التكنولوجيا وتمكينهن."},
              {eng:"Providing Professional training opportunities for young people and enabling them to participate effectively in the workforce.",arb:"تقديم فرص تدريب وتطوير مهني للشباب وتمكينهم من مشاركة فعالة في القوى العاملة."},
              {eng:"Promoting continuous learning and knowledge exchange in various fields of technology.",arb:"تعزيز التعلم المستمر وتبادل المعرفة في مجالات التكنولوجيا المختلفة."},
            ]
          },
          joinUs:{
            title:{ arb:"إنضم إلينا", eng:"Join Us",},
            p1:{arb:"تُعدّ التكنولوجيا الحديثة واحدة من أهم المجالات التي تشهد تطورًا مستمرًا، وهي القوة الدافعة وراء التغيير والتقدم في عصرنا الحالي. ومن خلال تطوير وتقديم الحلول التقنية المبتكرة، يمكننا تحقيق نهضة حقيقية في الوطن العربي. كمختصين في مجال التكنولوجيا وأحد أكبر مقدمي الحلول التقنيه المتكامله على مستوى الوطن العربي ندعوا المختصين ورواد الأعمال للإنضمام إلينا للعمل على مشاريع ملهمة ومثيرة سوف تساهم في تطوير مشاريع تكنولوجية ذات تأثير كبير على المجتمعات و كافة قطاعات الأعمال. يمكنك الإنضمام إلينا في أي من المجالات التالية:",eng:"Modern technology is one of the most important areas that is witnessing continuous development, and it is the driving force behind change and progress in our current era. By developing and providing innovative technical solutions, we can achieve a true renaissance in the Arab world. As specialists in the field of technology and one of the largest providers of integrated technical solutions in the Arab world, we invite specialists and entrepreneurs to join us to work on inspiring and exciting projects that will contribute to the development of technological projects that have a significant impact on societies and all business sectors. You can join us in any of the following areas:"},
            p2:{arb:"للإنضمام إلينا والتعرف أكثر على جميع الفرص المتاحة الرجار زيارة موقع المجموعة من خلال النقر على زر 'زيارة موقع المجموعة'. إنضم إلينا وكن جزءًا من رحلة الإبداع والتغيير.",eng:"To join us and learn more about all the available opportunities, please visit the group’s website by clicking on the “Visit our Group Website” button. Join us and be part of the journey of creativity and change."},
            lst:[
              {
                title:{arb:"لجان البحث والتطوير",eng:"Research and Development"},
                dec:{
                  arb:"لقد أثبت البحث العلمي أنه الدافع وراء التقدم التكنولوجي والازدهار الإقتصادي. إيمانا منا بأهمية البحث العلمي في تطوير التكنولوجيا نحن ندعوا جميع المختصين في الإنضمام إلى لجاننا المختلفة والتي تهدف إلى تقديم تحليل تقني متخصص و صياغة توصيات لمساعدة الشركات والقطاعات الحكومية من وضع سياسات ولوائح تساعد في الإستغلا الأمثل للتكنولوجيا المختلفة و تحسين الاتصال بين مجتمع التكنولوجيا والجمهور العام.",
                  eng:"Scientific research has proven to be the driver behind technological progress and economic prosperity. Believing in the importance of scientific research in developing technology, we invite all specialists to join our various committees, which aim to provide specialized technical analysis and formulate recommendations to help companies and government sectors develop policies and regulations that help in making optimal use of different technologies and improving communication between the technology community and the general public."
                },
              },
              {
                title:{arb:"تمويل المشاريع الريادية",eng:"Funding Startup Ventures"},
                dec:{
                  arb:"نحن نشجع على الإبداع والابتكار و نعرف جيدًا أن التمويل هو المحور الأساسي لتطوير المشاريع الريادية. إذا كنت رائد أعمال وتبحث عن ممول فسوف نقوم بدعمك ومساعدتك في كل ما يلزم من أجل تطوير أفكارك وتحويلها إلى حلول تكنولوجية متقدمة",
                  eng:"We encourage creativity and innovation and know very well that financing is the primary focus for developing entrepreneurial projects.  If you are an entrepreneur and looking for fund, we will support and assist you in everything necessary to develop your ideas and turn them into advanced technological solutions."},
              },
              {
                title:{arb:"فرص توظيف",eng:"Employment Opportunities"},
                dec:{arb:"نحن نؤمن بأهمية تطوير الموظفين وتعزيز مهاراتهم. بإنضمامك للعمل معنا سوف تحصل على فرص للتعلم والتطوير المستمر من خلال دورات تدريبية وورش عمل مخصصة وسوف تكون دائمًا على اطلاع بأحدث التطورات التكنولوجية وتساهم في تطويرها.",
                     eng:"We believe in the importance of developing employees and enhancing their skills. By joining to work with us, you will receive opportunities for continuous learning and development through customized training courses and workshops, and you will always be informed of the latest technological developments and contribute to their development."
                  },
              },
              {
                title:{arb:"شركاء النجاح",eng:"Partnership"},
                dec:{
                   arb:"لقد صممنا مجموعة من المنصات والادوات البرمجية المتكاملة التي تمثل المعنى الحقيقي لمفهوم 'تقديم الشركة كخدمة'. إذا كنت تملك الرغبة الحقيقة لكي تكون مالك أهم شركة برمجيات في بلدك فكل ما يلزمك هو الإنضمام إلينا كشريك ونحن نتكفل في تقديم كل ما تحتاجة من خدمات وحلول تقنية متكاملة سوف تساعدك من تحقيق التحول الرقمي بفعالية ونجاح",
                    eng:"We have designed a set of integrated platforms and software tools that represent the true meaning of the concept of “Company as a Service”. If you have the real desire to be the owner of the most important software company in your country, all you need to do is join us as a partner and we will provide all the integrated technical services and solutions you need that will help you achieve digital transformation effectively and successfully."
                  },
              },
            ],
            button:{eng:"Visit our Group Website",arb:"زيارة موقع المجموعة"},
          },
          contactus:{
            title:{eng:"Contact us",arb:"تواصل معنا"},
            button:{eng:"Send", arb:"ارسال"}
          }
        },
      },
      header:{
        "home":{eng:"Home",arb:"الرئيسية"},
        "AlsabahFamily":{eng:"Al-Sabah Family",arb:"عائلة الصباح"},
        "emirKuwait":{eng:"Emir of Kuwait",arb:"أمير دولة الكويت"},
        "visionofKuwait":{eng:"Vision of Kuwait",arb:"رؤية الكويت"},
        "messageofSheikhMuhammad":{eng:"Message from Chairman",arb:"رسالة رئيس مجلس الإدارة"},
        "messageofSheikhMuhammad2":{eng:"Chairman Message",arb:"رسالة رئيس مجلس الإدارة"},

        "ourGoal":{eng:"Goals",arb:"الأهداف"},
        "ourExperiences":{eng:"Experiences",arb:"الخبرات"},
        "ourServices":{eng:"Services",arb:"الخدمات"},
        "initiatives":{eng:"Initiatives",arb:"المبادرات"},
        "joins":{eng:"Join us",arb:"انضم إلينا"},
        contactUs:{eng:"Contact us",arb:"تواصل معنا"},
        groupe:{eng:"Visit our Group Website",arb:"زيارة موقع المجموعة"},
        aboutus:{eng:"About us",arb:"من نحن"},
        details:{eng:"More Details",arb:"التفاصيل",},

        committee:{arb:"لجان البحث والتطوير",eng:"Research & Development"},
          partnership:{arb:"شركاء النجاح",eng:"Partnership"},
          investment:{arb:"تمويل المشاريع الريادية",eng:"Funding Startup Ventures"},
          investment2:{arb:"تمويل المشاريع الريادية",eng:"Funding Startup"},
          employment:{arb:"فرص توظيف",eng:"Employment Opportunities"},

        copyRight:{eng:"The office of Sheikh Mohammed Al-Sabah. Al Right Reserved", arb:"مكتب الشيخ محمد الصباح. جميع الحقوق محفوظة"}

      }
    },

  },
  formapi:{
    strFullName:{label:{eng:"Name",arb:"الإسم"}},
    bigCountryID:{label:{eng:"Country of Residence",arb:"بلد الإقامة"}},
    bigDegreeID:{label:{eng:"",arb:"الدرجة العلمية"}},
    strUserEmail:{label:{eng:"E-mail Address",arb:"البريد الإلكتروني"}},
    strSubject:{label:{eng:"Subject",arb:"الموضوع"}},
    strMsg:{label:{eng:"Message",arb:"الرسالة"}},
    strVideoLink:{label:{eng:"Video Link",arb:"رابط الفيديو"}}
  },
};
