import * as tblMailQueries from "../../appQueries/gmsapp/tblMailQueries";
import { fetchData } from "../appFetch";
import * as appVariables from "../../appVariables";
import * as appFunctions from "../../appFunctions";
import { dictionary } from "../../appLanguage/dictionary";

var App_IsInTestingMode = appVariables.App_IsInTestingMode;

/**
 *
 * @param {{bigAppID?, bigSystemID?, bigMailID?, bigPurposeID, bigTypeID, strFullName, strEmail, strCountry, strDegree, lstCV, lstVideo, strBody, strTitle}} objInput
 */
export async function add(strPageInfo, appState, objInput) {
  try {
    const lang = appState?.clientInfo?.strLanguage;
    const requestQuery = tblMailQueries.AppAddMail(appState, {
      objectInput: {
        bigMailID: objInput?.bigMailID ? objInput?.bigMailID : appFunctions.clientFunction?.generateID(10),
        bigSystemID: appVariables.System_ID,
        jsnMailInfo: {
          strFullName: objInput?.strFullName,
          strEmail: objInput?.strEmail,
          strTitle: objInput?.strTitle,
          strBody: objInput?.strBody,
        },
      },
    });

    const response = await fetchData(strPageInfo, requestQuery);

    if (!response?.blnIsRequestSuccessful) {
      if (!App_IsInTestingMode) {
        response.response = dictionary?.shared?.alertFetchNote.cantFindRecords?.[lang];
      }
      return response;
    }

    return response;
  } catch (error) {
    appFunctions.logMessage(strPageInfo, "add-ERROR", error);
    return { blnIsRequestSuccessful: false, response: null };
  }
}

export async function findAll(strPageInfo, appState, objInput) {
  try {
    const lang = appState?.clientInfo?.strLanguage;
    const requestQuery = tblMailQueries.gmsAppFindAll(appState, {});

    const response = await fetchData(strPageInfo, requestQuery);

    if (!response?.blnIsRequestSuccessful) {
      if (!App_IsInTestingMode) {
        response.response = dictionary?.shared?.alertFetchNote.cantFindRecords?.[lang];
      }
      return response;
    }

    return response;
  } catch (error) {
    appFunctions.logMessage(strPageInfo, "findAll-ERROR", error);
    return { blnIsRequestSuccessful: false, response: null };
  }
}
