//#region ******************************************************* //
/*
  *This is the Router called from App.js
*/
//#endregion ************************************************* //

import React from 'react'
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "@cbmisorg/client-app";

import SecureRoute from '../appHelper/routing/SecureRoute';
import objAppRouting from '../appHelper/routing/appRouting';

import RouteLandingPage from '../components/public/LandingPage/RouteLandingPage';

// const RouteLandingPage  =lazy(()=>import('../components/public/LandingPage/RouteLandingPage'))


function Router() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<React.Fragment />}>
        <Route path={objAppRouting.Public_LandingPage.url} element={<SecureRoute><RouteLandingPage /></SecureRoute>} />
      </Route>
    )
  )

  return <RouterProvider router={router} />;

}

export default Router




