import { Avatar, Box, Container, Grid, Icon, Typography } from '@cbmisorg/client-app';
import React from 'react'
import * as appColor from '../../../../../../appHelper/appColor'
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary';
import { appStyle } from '../../../../../../appHelper/appStyle';

const lstIcon=["ManageAccounts","Lightbulb","TapAndPlay","BusinessCenter","CastForEducation","Radar","Female","Stream","flag"]

export default function OurGoal({lang}) {
  const labels = dictionary?.components?.public?.landingPage?.home?.ourGoal

  return (
    <React.Fragment>
      <Grid container  py-10 my-8 id="ourGoal" px-0 spacing={0}>
        <Grid item xs="12"  container  justify="center">
          <Container>
            <Grid container spacing={2}>
                <Grid item xs="12" sx={appStyle?.titleContainer} mb-5>
                    <Typography as="h5" sx={appStyle?.title2} px-3>{labels?.title?.[lang]}</Typography>
                </Grid>
            </Grid>
              <Grid container spacing={2}>
                  <Grid item xs="12">
                      <Typography as="" px-3>{labels?.p1?.[lang]}</Typography>
                  </Grid>
                  <Grid item xs="12" lg="12" container spacing={1} justify="center" alignSelf={'flex-start'}>
                  {
                    labels?.lst?.map((slide,index)=>{
                      return(
                        <Grid item xs="12" sm="6" lg="4"  key={index}>
                        <Box p-2 className={lang==="arb"?"boxViewArb":"boxView"}>
                              <Grid container spacing={2} justify="start" sx={{borderBottom:`1px solid ${appColor?.App_Primary_Color}`}}>
                                <Grid item xs="2" p-0>
                                  <Avatar className='boxViewAvatar'>
                                    <Icon icon={lstIcon?.[index]}  />
                                  </Avatar>
                                </Grid>
                                <Grid item xs="10">
                                    <Typography as="subtitle1" color="primary" sx={{textAlign:"start !important"}}>{slide?.title?.[lang]}</Typography>
                                  </Grid>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid item xs="12">
                                  <Typography as="body2" sx={{textAlign:"justify !important"}}>{slide?.point?.[lang]}</Typography>
                                </Grid>
                              </Grid>
                            </Box>
                        </Grid>
                        )
                      })
                  }
                  </Grid>
              </Grid>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
