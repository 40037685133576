import { Container, Grid, Typography,Alert, ClientFunction, Button } from '@cbmisorg/client-app';
import React, { useContext, useEffect, useState } from 'react'
import * as appColor from "../../../../../../appHelper/appColor"
import * as appFunctions from "../../../../../../appHelper/appFunctions"
import * as appVariables from "../../../../../../appHelper/appVariables"
import * as tblMailFetch from "../../../../../../appHelper/appData/fetchapi/tblMailQueries"
import {AppContext} from '../../../../../../configuration/contextapi/AppContext';
import {dictionary} from '../../../../../../appHelper/appLanguage/dictionary';


import { FormData, FormField } from '../../../../../../appHelper/formApi/FormAPI';
import { appStyle } from '../../../../../../appHelper/appStyle';

const strPageInfo = "@src/component/subSystems/public/home/components/contact/ContactUs.js"
  const classes  = {
    background:{
        boxShadow: "none",
        backgroundColor:appColor.App_Second_Color,
        "&before":{
          content:"",
          position:"absolute",
          width:"100%",
          height:"100%",
          display:"block",
          background:appColor.App_Second_Color,
          backgroundImage:`url(${require("../../../../../../assets/images/bg-footer.png")})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition:"bottom center",
          opacity: "0.4",
          mixBlendMode: "overlay",
          transition:"background 0.3s, border-radius 0.3s, opacity 0.3s"
        },
    },
    button:{
        background:appColor.App_Primary_Color,
        fontWeight:800,
        width:"100%"
    },
    title: {
      textAlign: "center !important",
      position:"absolute",
      top:"-18px", left:"50%",
      background:appColor?.App_Beige_Color4,
      color:appColor.App_Primary_Color,
      transform:"translateX(-50%)",
      whiteSpace: "nowrap",
    },
  }

function ContactUs({lang}) {
    const { appState } = useContext(AppContext);
    const labels = dictionary?.components?.public?.landingPage?.home?.contactus
    const [isLoading, setIsLoading] = useState(false)


    const fields = FormData({
        strFullName: {
          strControl: "text",
          objValidations: { required: true,},
          objLanguage: false,
        },
        strUserEmail: {
          strControl: "text",
          objValidations: { required: true, email: true },
          objLanguage: false,
        },
        strSubject:{
            strControl: "text",
            objValidations: { required: true},
            objLanguage: false,
        },
        strMsg:{
          strControl: "textarea",
          objValidations: { required: true},
          objLanguage: false,
        },
      });

      const handleSubmit = async() =>{
        try {
        if(!fields?.isFormValid()){
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error");
            return;
        }
        if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.fetchData_InProgress[lang], "warning");
            return;
        }
        setIsLoading(true);

        const bigMailID = ClientFunction.generateID(10)
        const result = await tblMailFetch.add(strPageInfo, appState,{
            bigMailID: bigMailID,
            bigAppID: appState?.clientInfo?.bigAppID,
            bigSystemID: appState?.clientInfo?.bigSystemID,
            bigPurposeID: appVariables.objMailPurpose?.Contact_US?.Inquiry,
            bigTypeID: appVariables?.objMailType?.Contact_US,
            strFullName: fields?.strFullName?.getValue(),
            strEmail: fields?.strUserEmail?.getValue(),
            strCountry: "",
            strDegree: "",
            lstCV: "",
            lstVideo: "",

            strTitle: fields?.strSubject?.getValue(),
            strBody: fields?.strMsg?.getValue(),
          },);
          
        setIsLoading(false);
        if (!result.blnIsRequestSuccessful) {
            Alert.viewAlert(result.response, "warning");
            return;
        }

        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully?.[lang], "success");
        fields?.resetField();
        return

        } catch (error) {
        appFunctions.logMessage(strPageInfo, error,"submitLogin-ERROR");
        setIsLoading(false);
        }
    }

    useEffect(() => {
    }, [])

  return (
    <React.Fragment>
        <Grid container sx={{...appStyle?.container,...classes?.background}} py-0 my-0 id="contactus" px-0 spacing={0} >
        <Grid item xs="12"  container my-5  justify="center">
          <Container py-10>
              <Grid container spacing={2} justify="center">
                  <Grid item xs="12" sx={appStyle?.titleContainer2}>
                      <Typography as="h5"sx={classes?.title} px-3>{labels?.title?.[lang]}</Typography>
                  </Grid>
                  <Grid item xs="12" lg="8" py-10 className='contactContainer'>
                        <Grid container justify="center">
                        <Grid item md={"12"} xs={"12"} py-2>
                            <FormField objHandler={fields.strFullName} icon="accountCircle" iconColor={appColor.App_Primary_Color} />
                        </Grid>
                        <Grid item md={"12"} xs={"12"} py-2>
                            <FormField objHandler={fields.strUserEmail} icon="email" iconColor={appColor.App_Primary_Color} />
                        </Grid>
                        <Grid item md={"12"} xs={"12"} py-2>
                                <FormField objHandler={fields.strSubject} iconColor={appColor.App_Primary_Color} />
                        </Grid>
                        <Grid item md={"12"} xs={"12"} py-2>
                            <FormField objHandler={fields.strMsg}  iconColor={appColor.App_Primary_Color} />
                        </Grid>
                        <Grid item xs={"12"} py-2>
                            <Button
                            label={<Typography as="subtitle1">{labels?.button?.[lang]}</Typography>}
                            py-0
                            sx={classes.button}
                            onClick={handleSubmit}
                            />
                        </Grid>
                        </Grid>
                    </Grid>

              </Grid>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ContactUs
