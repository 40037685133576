import { Container, Grid, Icon, Paper, Typography } from '@cbmisorg/client-app';
import React from 'react'
import * as appColor from '../../../../../../appHelper/appColor'
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary';
import { appStyle } from '../../../../../../appHelper/appStyle';

const classes = {
  paper:{
    borderColor:appColor?.App_Second_Color,
    background:appColor?.App_Beige_Color4,
    minHeight:"100px",
    sm:{
      minHeight:"120px"
    }
  },
  paperTitle:{
    fontFamily: "'Lemonada', cursive , Alexandria,'Reem Kufi', sans-serif !important",
    color: appColor?.App_Primary_Color,
  },
};

const lstIcon=["AutoGraph","LocalLibrary","Work","HealthAndSafety","ModelTraining","AccountBalance","AttachMoney","public"]

function VisionOfKuwait({lang}) {
  const labels = dictionary?.components?.public?.landingPage?.home?.visionofKuwait

  return (
    <React.Fragment>
      <Container  width="fluid" id="visionofKuwait">
          <Grid container>
              <Grid item xs="12"  container spacing="3" justify="center">
                  <Container>
                      <Grid container spacing={3}>
                          <Grid item xs="12" py-2>
                            <Typography as="h4" sx={appStyle?.title1}>{labels?.subtitle?.[lang]}</Typography>
                          </Grid>
                          <Grid item xs="12" pb-2>
                              <Typography as="h6">{labels?.title?.[lang]}</Typography>
                          </Grid>
                          <Grid item xs="12">
                              <Typography as="body1">{labels?.p1?.[lang]}</Typography>
                          </Grid>
                          <Grid item xs="12" container spacing={0} justify="center" alignSelf={'flex-start'}>
                          {
                            labels?.lst?.map((item,index)=>{
                              return(
                                <Grid item xs="12" lg="6" key={index} pt-2 px-lg-2 px-xl-2>
                                  <Paper elevation={0} outline={0} sx={classes?.paper} className='paperBox'>
                                    <Grid container>
                                      <Grid item>
                                          <Icon icon={lstIcon?.[index]} color="primary" size='25px' m-0 p-0/>
                                      </Grid>
                                      <Grid item xs="10" lg="11">
                                        <Typography as="subtitle2" sx={classes.paperTitle}>{item?.title?.[lang]}</Typography>
                                      </Grid>
                                      <Grid item xs="12">
                                        <Typography as="body2">{item?.point?.[lang]}</Typography>
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </Grid>
                                )
                              })
                          }
                          </Grid>
                          {/* <Grid item xs="12" lg="9" pt-0 mx-auto>
                            <Typography as="body1">{labels?.p2?.[lang]}</Typography>
                          </Grid> */}
                      </Grid>
                  </Container>
              </Grid>
          </Grid>
      </Container>
    </React.Fragment>
  )
}

export default VisionOfKuwait
