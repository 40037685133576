import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, CardImage, Container, Grid, Icon, Paper, Typography } from '@cbmisorg/client-app';
import * as appColor from '../../../../../../appHelper/appColor'
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary';
import { appStyle } from '../../../../../../appHelper/appStyle';
import { App_Download_URL } from "../../../../../../appHelper/appVariables";

const experience1 = App_Download_URL + "eperience1.jpg";
const experience2 = App_Download_URL + "eperience2.jpg";
const experience3 = App_Download_URL + "eperience3.jpg";
const experience4 = App_Download_URL + "eperience4.jpg";
const experience5 = App_Download_URL + "eperience5.jpg";
const experience6 = App_Download_URL + "eperience6.jpg";

const lstIcon=["AutoAwesome","BarChart","SupervisedUserCircle","VerifiedUser","Insights","LocationCity"]
const lstImg=[experience1,experience2,experience3,experience4,experience5,experience6]

const classes = {
  background: {
      background:`url(${require("../../../../../../assets/images/kuwait_city_experiences.jpg")})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
  },
  avatarIcon:{
    width:"40px !important",
    height:"40px !important",
    background:`${appColor.App_Dark_Color} !important`,
    border:`1px solid ${appColor?.App_Primary_Color}`,
  },
  paper:{
    backgroundColor:appColor?.App_Light_Color,
    transition:"background 0.3s, border-radius 0.3s, opacity 0.3s",
    // top:"-85px !important",
  },
  button:{
    sm:{
      width:"100% !important",
      top:"20px !important"
    }
  }
}

export default function OurExperiences({lang}) {
    const labels = dictionary?.components?.public?.landingPage?.home?.ourExperiences
    const [state, setState] = useState({openIndex:-1})

    useEffect(() => {
    }, [])

    const openCard=(index)=>()=>{
      if(state?.openIndex !== index){
        state.openIndex = index
      }else{
        state.openIndex = -1
      }
      setState({...state})

    }

  return (
     <React.Suspense>
     <Grid container pt-10 mt-7 id="ourExperiences" px-0 spacing={0}>
        <Grid item xs="12"  container  justify="center">
          <Container>
              <Grid container spacing={2}>
                  <Grid item xs="12" sx={appStyle?.titleContainer} mb-2>
                      <Typography as="h5" sx={appStyle?.title2} px-3>{labels?.title?.[lang]}</Typography>
                  </Grid>
              </Grid>
          </Container>
        </Grid>
      </Grid>
      <Grid container px-0 spacing={0} sx={{...classes?.background}} >
        <Grid item xs="12"  container  justify="center">
          <Container pb-10>
              <Grid container spacing={3} >
                  <Grid item xs="12">
                    <Typography as="" px-3>{labels?.p1?.[lang]}</Typography>
                  </Grid>
                  <Grid item xs="12" container spacing={3} justify="center" alignSelf={'flex-start'} px-1>
                  {
                    labels?.lst?.map((item,index)=>{
                      return(
                        <Grid item xs="12" sm="6" lg="4" key={index}>
                          <Card>
                          <CardImage src={lstImg?.[index]} className={`${lang==="arb"?"cardViewArb":"cardView"} ${state?.openIndex === index?"cardViewOpen":""}`} loading='lazy'p-0 m-0>
                                  {
                                    state?.openIndex === index ?(
                                      <Grid container justify="center" spacing={1} sx={{height:"100%"}} p-0 m-0 onMouseLeave={openCard(index)}>
                                        <Grid item xs="12" py-2>
                                            <Avatar sx={{...classes?.avatarIcon}} m-0 className='cardViewAvatar'>
                                                <Icon icon={lstIcon?.[index]} size="28px" color="primary"/>
                                            </Avatar>
                                            </Grid>
                                            <Grid item xs="12">
                                                <Typography as="body2" color="secondary" px-0 m-0>{item?.point?.[lang]}</Typography>
                                            </Grid>
                                            <Grid item py-0 alignSelf={"flex-end"} className='cardViewIconUp'>
                                                <Button icon="arrowCircleUp" mode="link" color="secondary" p-1 onClick={openCard(index)}/>
                                            </Grid>
                                        </Grid>
                                    ):(
                                      <Grid container justify="center"  >
                                      <Grid item xs="12" py-2>
                                          <Typography as="h6" color="primary">{item?.title?.[lang]}</Typography>
                                        </Grid>
                                        <Grid item pt-0 >
                                            <Button icon="arrowCircleDown" mode="link" color="primary" onClick={openCard(index)} sx={{padding:"1px auto !important"}}/>
                                        </Grid>
                                      </Grid>
                                    )
                                  }
                          </CardImage>
                          </Card>
                        </Grid>
                        )
                      })
                  }
                </Grid>
              </Grid>
          </Container>
        </Grid>
      </Grid>
      {/* <Container px-5 py-5>
        <Paper elevation={0} sx={classes.paper}>
            <Grid container justify="center">
                <Grid item xs="12" lg="10">
                    <Typography as="body1">{labels?.p2?.[lang]}</Typography>
                </Grid>
                <Grid item xs="12" lg="2">
                    <Button 
                        label={<Typography as="subtitle2" color="secondary">{labels?.button?.[lang]}</Typography>}
                        sx={classes?.button}
                    />
                </Grid>
            </Grid>
        </Paper>
      </Container> */}
    </React.Suspense>
  )
}
